const middleware = {}

middleware['clearValidationErrors'] = require('../middleware/clearValidationErrors.js')
middleware['clearValidationErrors'] = middleware['clearValidationErrors'].default || middleware['clearValidationErrors']

middleware['formChangeHandler'] = require('../middleware/formChangeHandler.js')
middleware['formChangeHandler'] = middleware['formChangeHandler'].default || middleware['formChangeHandler']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['hasSubscription'] = require('../middleware/hasSubscription.js')
middleware['hasSubscription'] = middleware['hasSubscription'].default || middleware['hasSubscription']

middleware['pages'] = require('../middleware/pages.js')
middleware['pages'] = middleware['pages'].default || middleware['pages']

export default middleware
