import Vue from 'vue';
import {library, config} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faLink,
  faBold,
  faItalic,
  faUnderline,
  faSmile,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faSignOut,
  faCaretDown,
  faCaretUp,
  faEye,
  faSearch,
  faPlus,
  faMinus,
  faEdit,
  faFile,
  faSortSizeUpAlt,
  faSortSizeDownAlt,
  faTrashAlt,
  faTimes,
  faSort,
  faShare,
  faMapMarkerAlt,
  faCalendarAlt,
  faPaperclip,
  faDownload,
  faSpinner,
  faBars,
  faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faFacebookSquare,
  faTwitterSquare,
  faGooglePlusSquare,
  faInstagramSquare,
} from '@fortawesome/free-brands-svg-icons';

config.autoAddCss = false;

library.add(
  faLink,
  faCaretDown,
  faCaretUp,
  faFacebookSquare,
  faTwitterSquare,
  faGooglePlusSquare,
  faInstagramSquare,
  faBold,
  faItalic,
  faUnderline,
  faSignOut,
  faEye,
  faFile,
  faSearch,
  faPlus,
  faMinus,
  faEdit,
  faSortSizeUpAlt,
  faSortSizeDownAlt,
  faTrashAlt,
  faTimes,
  faSort,
  faShare,
  faSmile,
  faMapMarkerAlt,
  faCalendarAlt,
  faPaperclip,
  faDownload,
  faSpinner,
  faBars,
  faInfoCircle,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
