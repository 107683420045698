export const state = () => ({
  release: process.env.RELEASE,
  page: {},
});

export const actions = {
  async nuxtServerInit({commit}) {
    //
  },
  async getPage({commit}, slug = '') {
    const {data} = await this.$axios.get(`/page?segments=${slug}`);

    commit('SET_PAGE', data.data);
  },
};

export const mutations = {
  SET_PAGE(state, payload) {
    state.page = payload;
  },
};
