<template>
  <div class="bg-primary-light">
    <div class="container flex items-center justify-between h-16 lg:h-25">
      <button class="block mr-3 lg:hidden" @click="$nuxt.$emit('toggleSidebar')">
        <font-awesome-icon :icon="['fas', 'bars']" size="lg"/>
      </button>
      <nuxt-link to="/">
        <img src="~/assets/img/logo.svg" class="h-12 lg:h-auto object-contain" alt="Mijn Superkracht.nl">
      </nuxt-link>
      <div class="flex flex-wrap w-2/3 justify-end items-center h-full">
        <user-toolbar/>
      </div>
      <div class="placeholder-bar block lg:hidden"/>
    </div>
  </div>
</template>

<script>
import UserToolbar from '~/components/base/UserToolbar';
import roles from '~/plugins/mixins/roles';

export default {
  name: 'Toolbar',
  components: {UserToolbar},
  mixins: [roles],
};
</script>
