import {BEGELEIDER, LEERLING, OUDER, BETROKKENE, BEHEERDER} from '~/plugins/constants';

export default {
  computed: {
    isBegeleider() {
      return this.$auth.user.role_id === BEGELEIDER;
    },
    isPraktijkbeheerder() {
      return this.$auth.user.is_praktijkbeheerder;
    },
    isLeerling() {
      return this.$auth.user.role_id === LEERLING;
    },
    isOuder() {
      return this.$auth.user.role_id === OUDER;
    },
    isBetrokkene() {
      return this.$auth.user.role_id === BETROKKENE;
    },
    isBeheerder() {
      return this.$auth.user.role_id === BEHEERDER;
    },
  },
};
