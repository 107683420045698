export const BEHEERDER = 5;
export const BEGELEIDER = 7;
export const LEERLING = 8;
export const OUDER = 9;
export const BETROKKENE = 10;

export const ACCOUNT_VERWIJDEREN = 'Account verwijderen';
export const LEVER_OPDRACHT_IN = 'Lever opdracht in';
export const OPDRACHT_VERWIJDEREN = 'Opdracht verwijderen';
export const OPDRACHT_ARCHIVEREN = 'Opdracht archiveren';
export const OPDRACHT_VERSTUREN = 'Opdracht versturen';
export const OPDRACHT_DELEN = 'Delen met andere begeleiders';
export const UITWERKING_GOEDKEUREN = 'Uitwerking goedkeuren';
export const UITWERKING_ARCHIVEREN = 'Uitwerking archiveren';
export const UITWERKING_VERWIJDEREN = 'Uitwerking verwijderen';
export const LEERLING_ARCHIVEREN = 'Leerling op non-actief';
export const LEERLING_VERWIJDEREN = 'Leerling verwijderen';
export const DOCUMENT_DELEN = 'Document delen';
export const ERVARING_OPVRAGEN = 'Ervaring opvragen';
export const BERICHT_STUREN = 'Bericht sturen';
export const FACTUUR_VERSTUREN = 'Factuur versturen';
export const LEERLING_KOPPELEN = 'Leerling koppelen';
export const BEGELEIDER_ONTKOPPELEN = 'Begeleider ontkoppelen';
export const SESSIEVERSLAG_VERWIJDEREN = 'Sessieverslag verwijderen';
export const AANVULLING_VERWIJDEREN = 'Aanvulling verwijderen';

export const OEFENINGEN = 'oefeningen';
export const UITWERKINGEN = 'uitwerkingen';
export const VERSLAGEN = 'verslagen';
export const AANVULLINGEN = 'aanvullingen';

export const JAARLIJKS = 'Jaarlijks';
export const MAANDELIJKS = 'Maandelijks';

export const WEBINARS = 'Webinars';
export const TRAININGEN = 'Trainingen';

export const VERSTUURD = 'verstuurd';
export const ONTVANGEN = 'ontvangen';

export const REGISTRATION_TYPES = ['Begeleider', 'Praktijk'];
