<template>
  <div class="mb-4">
    <div :class="type === 'number' ? 'flex justify-between items-end' : 'block'">
      <label :class="type === 'number' ? 'mb-0' : 'mb-1'" class="block h5" :for="id">
        <slot/>
        <span v-if="required" class="text-secondary">
          *
        </span>
      </label>
      <input
        :id="id"
        ref="input"
        :value="value"
        :required="required"
        :class="type === 'number' ? 'w-1/2' : 'w-full'"
        class="appearance-none border-2 border-primary-medium rounded py-2 px-3 text-primary-medium text-sm leading-tight"
        :type="type"
        :placeholder="placeholder"
        :disabled="disabled"
        :min="min"
        :max="max"
        :step="step"
        :readonly="readOnly"
        :autocomplete="autocomplete"
        @input="updateInput"
      >
    </div>
    <span v-if="errorMessages.length" class="text-secondary text-sm">
      {{ errorMessages[0] | ucFirst }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    id: {
      type: String,
      default: 'id_' + Math.random().toString().replace('.', ''),
    },
    type: {
      type: String,
      default: 'text',
    },
    min: {
      type: String,
      default: 'text',
    },
    max: {
      type: String,
      default: 'text',
    },
    step: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: undefined,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    updateInput() {
      this.$emit('input', this.$refs.input.value);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ input:focus {
  outline: theme('colors.secondary.dark') auto 1px;
}
</style>
