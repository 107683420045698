export const state = () => ({
  hasChanged: false,
});

export const mutations = {
  SET_CHANGED(state, payload) {
    state.hasChanged = payload;
  },
};

export const actions = {
  setChanged({commit}, payload) {
    commit('SET_CHANGED', payload);
  },
};

export const getters = {
  hasChanged(state) {
    return state.hasChanged;
  },
};
