import {ApolloLink, split} from 'apollo-link';
import {onError} from 'apollo-link-error';
import {createUploadLink} from 'apollo-upload-client';
import {BatchHttpLink} from 'apollo-link-batch-http';
import {getMainDefinition} from 'apollo-utilities';
import jwtDecode from 'jwt-decode';
import Observable from 'zen-observable';

export default function ({app}) {
  const errorLink = onError(({graphQLErrors}) => {
    if (graphQLErrors) {
      graphQLErrors.map((error) => {
        if (error.message === 'validation') {
          app.store.dispatch('validation/setErrors', error.extensions.validation);
        }
      });
    }
  });

  const tokenCheckLink = new ApolloLink((operation, forward) => {
    const token = app.$cookies.get('auth._token.local');
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp < Math.floor(Date.now() / 1000)) {
        return new Observable((subscriber) => {
          app.$toast.error('Uw sessie is verlopen. U wordt doorgestuurd naar de inlogpagina.');
          app.$auth.logout();
          app.router.push({name: 'login'});

          subscriber.error('session expired');
        });
      }
    }

    return forward ? forward(operation) : null;
  });

  const linkOptions = {
    uri: process.env.API_URL.replace('/api', '') + '/graphql',
    fetch: (uri, options) => {
      app.store.dispatch('validation/clearErrors');

      // Add authorization token
      const token = app.$cookies.get('auth._token.local');

      if (token) {
        options.headers.authorization = token;
      }

      return fetch(uri, options);
    },
  };

  const link = split(
    // Split based on operation type
    ({query}) => {
      const {kind, operation} = getMainDefinition(query);

      return kind === 'OperationDefinition' && ['mutation', 'subscription'].includes(operation);
    },
    createUploadLink(linkOptions),
    new BatchHttpLink(linkOptions),
  );

  return {
    defaultHttpLink: false,
    link: ApolloLink.from([
      tokenCheckLink,
      errorLink,
      link,
    ]),
  };
}
