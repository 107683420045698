<template>
  <div class="flex flex-col min-h-screen">
    <toolbar class="z-50"/>
    <div class="flex main-content flex-grow">
      <sidebar/>
      <main class="flex-grow z-40 pb-16">
        <div v-if="isDetailPage" class="hidden md:block container pt-10 -mb-8">
          <button class="btn btn-secondary text-xs" @click="goToPreviousPage">
            ‹ Terug
          </button>
        </div>
        <nuxt/>
      </main>
    </div>
  </div>
</template>

<script>
import Toolbar from './partials/Toolbar';
import Sidebar from './partials/Sidebar';

export default {
  components: {Sidebar, Toolbar},
  computed: {
    isDetailPage() {
      const routeName = this.$route.name;
      const splitRoutePath = this.$route.path.split('/');

      return routeName.includes('-') && splitRoutePath.length > 1;
    },
  },
  methods: {
    goToPreviousPage() {
      return window.history.length > 2 ? this.$router.go(-1) : this.$router.push('/');
    },
  },
};
</script>
