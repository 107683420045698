<template>
  <button @click="logOut">
    <font-awesome-icon :icon="['fas', 'sign-out']" class="mr-2"/>
    Uitloggen
  </button>
</template>

<script>
export default {
  methods: {
    async logOut() {
      await this.$auth.logout();
      Object.values(this.$apollo.provider.clients).forEach(client => client.cache.reset());
    },
  },
};
</script>
