import Vue from 'vue';

Vue.mixin({
  methods: {
    userHasService(service) {
      return this.$auth.user.enabled_services.some(s => s.toLowerCase() === service.toLowerCase());
    },
    userHasPermission(permissionId) {
      const {user} = this.$auth;
      const permissionIdWithRole = `${user.role_id}-${permissionId}`;

      if (!user?.user_permissions) {
        return false;
      }

      return user.user_permissions.some(({id, value}) => {
        return id === permissionIdWithRole && value === true;
      });
    },
  },
});
