import Vue from 'vue';

const Config = {
  install(Vue) {
    Vue.mixin({
      data: () => ({
        nuxtBaseUrl: process.env.NUXT_URL,
      }),
      methods: {
        nuxtUrl(path) {
          return this.nuxtBaseUrl + path;
        },
      },
    });
  },
};

Vue.use(Config);
