import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _18fc0f4a = () => interopDefault(import('../pages/2fa.vue' /* webpackChunkName: "pages/2fa" */))
const _0da19f31 = () => interopDefault(import('../pages/abonnementen.vue' /* webpackChunkName: "pages/abonnementen" */))
const _7ad92385 = () => interopDefault(import('../pages/activiteiten/index.vue' /* webpackChunkName: "pages/activiteiten/index" */))
const _b93ab882 = () => interopDefault(import('../pages/agenda.vue' /* webpackChunkName: "pages/agenda" */))
const _7c26e0f1 = () => interopDefault(import('../pages/begeleiders/index.vue' /* webpackChunkName: "pages/begeleiders/index" */))
const _0921ed24 = () => interopDefault(import('../pages/beheerder/index.vue' /* webpackChunkName: "pages/beheerder/index" */))
const _31436e9e = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _c44d6d92 = () => interopDefault(import('../pages/documenten.vue' /* webpackChunkName: "pages/documenten" */))
const _0daecdb0 = () => interopDefault(import('../pages/facturen/index.vue' /* webpackChunkName: "pages/facturen/index" */))
const _ea4c8684 = () => interopDefault(import('../pages/forum.vue' /* webpackChunkName: "pages/forum" */))
const _7480d6c8 = () => interopDefault(import('../pages/handleiding.vue' /* webpackChunkName: "pages/handleiding" */))
const _45e843cc = () => interopDefault(import('../pages/handleiding/_slug/index.vue' /* webpackChunkName: "pages/handleiding/_slug/index" */))
const _64034cbb = () => interopDefault(import('../pages/leerlingen/index.vue' /* webpackChunkName: "pages/leerlingen/index" */))
const _abb889f4 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _28ed185d = () => interopDefault(import('../pages/mijn-profiel.vue' /* webpackChunkName: "pages/mijn-profiel" */))
const _ec03b0b0 = () => interopDefault(import('../pages/oefeningen/index.vue' /* webpackChunkName: "pages/oefeningen/index" */))
const _31b4b5bc = () => interopDefault(import('../pages/opdrachten/index.vue' /* webpackChunkName: "pages/opdrachten/index" */))
const _65d542c8 = () => interopDefault(import('../pages/overige-informatie/index.vue' /* webpackChunkName: "pages/overige-informatie/index" */))
const _3999d2d8 = () => interopDefault(import('../pages/praktijk/index.vue' /* webpackChunkName: "pages/praktijk/index" */))
const _ff436276 = () => interopDefault(import('../pages/sessies/index.vue' /* webpackChunkName: "pages/sessies/index" */))
const _310e0076 = () => interopDefault(import('../pages/wachtwoord-vergeten/index.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/index" */))
const _faae2162 = () => interopDefault(import('../pages/activiteiten/aanmaken.vue' /* webpackChunkName: "pages/activiteiten/aanmaken" */))
const _51b8127e = () => interopDefault(import('../pages/begeleider/geen-toegang.vue' /* webpackChunkName: "pages/begeleider/geen-toegang" */))
const _56d6636d = () => interopDefault(import('../pages/begeleiders/toevoegen.vue' /* webpackChunkName: "pages/begeleiders/toevoegen" */))
const _dbffa720 = () => interopDefault(import('../pages/beheerder/activiteiten/index.vue' /* webpackChunkName: "pages/beheerder/activiteiten/index" */))
const _798abbb4 = () => interopDefault(import('../pages/beheerder/blogs/index.vue' /* webpackChunkName: "pages/beheerder/blogs/index" */))
const _6876cf93 = () => interopDefault(import('../pages/beheerder/facturen/index.vue' /* webpackChunkName: "pages/beheerder/facturen/index" */))
const _1cbd04be = () => interopDefault(import('../pages/beheerder/gebruikers/index.vue' /* webpackChunkName: "pages/beheerder/gebruikers/index" */))
const _40b84b92 = () => interopDefault(import('../pages/leerlingen/toevoegen.vue' /* webpackChunkName: "pages/leerlingen/toevoegen" */))
const _afcdc3c4 = () => interopDefault(import('../pages/opdrachten/archief.vue' /* webpackChunkName: "pages/opdrachten/archief" */))
const _1c4201c4 = () => interopDefault(import('../pages/overige-informatie/toevoegen.vue' /* webpackChunkName: "pages/overige-informatie/toevoegen" */))
const _5f1f07b0 = () => interopDefault(import('../pages/praktijk/abonnementen.vue' /* webpackChunkName: "pages/praktijk/abonnementen" */))
const _43b4145f = () => interopDefault(import('../pages/praktijk/facturen/index.vue' /* webpackChunkName: "pages/praktijk/facturen/index" */))
const _42a5fcb0 = () => interopDefault(import('../pages/praktijk/opdrachten.vue' /* webpackChunkName: "pages/praktijk/opdrachten" */))
const _32c3a2fc = () => interopDefault(import('../pages/praktijk/begeleiders/leerling-dossier.vue' /* webpackChunkName: "pages/praktijk/begeleiders/leerling-dossier" */))
const _df6ce9b0 = () => interopDefault(import('../pages/beheerder/activiteiten/_slug.vue' /* webpackChunkName: "pages/beheerder/activiteiten/_slug" */))
const _7cf7fe44 = () => interopDefault(import('../pages/beheerder/blogs/_slug.vue' /* webpackChunkName: "pages/beheerder/blogs/_slug" */))
const _1b066376 = () => interopDefault(import('../pages/beheerder/gebruikers/_slug.vue' /* webpackChunkName: "pages/beheerder/gebruikers/_slug" */))
const _41fd7317 = () => interopDefault(import('../pages/praktijk/facturen/_slug.vue' /* webpackChunkName: "pages/praktijk/facturen/_slug" */))
const _7922823d = () => interopDefault(import('../pages/activiteiten/_slug.vue' /* webpackChunkName: "pages/activiteiten/_slug" */))
const _7a703fa9 = () => interopDefault(import('../pages/begeleiders/_slug.vue' /* webpackChunkName: "pages/begeleiders/_slug" */))
const _34b0b12e = () => interopDefault(import('../pages/blogs/_slug.vue' /* webpackChunkName: "pages/blogs/_slug" */))
const _111c1040 = () => interopDefault(import('../pages/facturen/_slug.vue' /* webpackChunkName: "pages/facturen/_slug" */))
const _1a198fb6 = () => interopDefault(import('../pages/leerlingen/_slug/index.vue' /* webpackChunkName: "pages/leerlingen/_slug/index" */))
const _ef70f340 = () => interopDefault(import('../pages/oefeningen/_slug.vue' /* webpackChunkName: "pages/oefeningen/_slug" */))
const _7b831812 = () => interopDefault(import('../pages/opdrachten/_slug/index.vue' /* webpackChunkName: "pages/opdrachten/_slug/index" */))
const _641ea180 = () => interopDefault(import('../pages/overige-informatie/_slug.vue' /* webpackChunkName: "pages/overige-informatie/_slug" */))
const _37e33190 = () => interopDefault(import('../pages/praktijk/_slug.vue' /* webpackChunkName: "pages/praktijk/_slug" */))
const _7ea7ad7d = () => interopDefault(import('../pages/sessies/_slug.vue' /* webpackChunkName: "pages/sessies/_slug" */))
const _170ee4e7 = () => interopDefault(import('../pages/wachtwoord-instellen/_token.vue' /* webpackChunkName: "pages/wachtwoord-instellen/_token" */))
const _e522ded2 = () => interopDefault(import('../pages/wachtwoord-vergeten/_token.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/_token" */))
const _024136bf = () => interopDefault(import('../pages/leerlingen/_slug/bewerken.vue' /* webpackChunkName: "pages/leerlingen/_slug/bewerken" */))
const _13e277c7 = () => interopDefault(import('../pages/leerlingen/_slug/informatie/index.vue' /* webpackChunkName: "pages/leerlingen/_slug/informatie/index" */))
const _9193d322 = () => interopDefault(import('../pages/leerlingen/_slug/sessieverslag-schrijven.vue' /* webpackChunkName: "pages/leerlingen/_slug/sessieverslag-schrijven" */))
const _2bf256d9 = () => interopDefault(import('../pages/leerlingen/_slug/aantekeningen/toevoegen.vue' /* webpackChunkName: "pages/leerlingen/_slug/aantekeningen/toevoegen" */))
const _15273ff6 = () => interopDefault(import('../pages/leerlingen/_slug/aantekeningen/_id.vue' /* webpackChunkName: "pages/leerlingen/_slug/aantekeningen/_id" */))
const _17ba36e8 = () => interopDefault(import('../pages/leerlingen/_slug/informatie/_aanvulling/index.vue' /* webpackChunkName: "pages/leerlingen/_slug/informatie/_aanvulling/index" */))
const _d5f4ef68 = () => interopDefault(import('../pages/leerlingen/_slug/sessieverslagen/_verslag_id/index.vue' /* webpackChunkName: "pages/leerlingen/_slug/sessieverslagen/_verslag_id/index" */))
const _13df6602 = () => interopDefault(import('../pages/leerlingen/_slug/informatie/_aanvulling/aanpassen.vue' /* webpackChunkName: "pages/leerlingen/_slug/informatie/_aanvulling/aanpassen" */))
const _774a29c2 = () => interopDefault(import('../pages/leerlingen/_slug/sessieverslagen/_verslag_id/aanpassen.vue' /* webpackChunkName: "pages/leerlingen/_slug/sessieverslagen/_verslag_id/aanpassen" */))
const _05720340 = () => interopDefault(import('../pages/opdrachten/_slug/_leerling.vue' /* webpackChunkName: "pages/opdrachten/_slug/_leerling" */))
const _3f07d022 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/2fa",
    component: _18fc0f4a,
    name: "2fa"
  }, {
    path: "/abonnementen",
    component: _0da19f31,
    name: "abonnementen"
  }, {
    path: "/activiteiten",
    component: _7ad92385,
    name: "activiteiten"
  }, {
    path: "/agenda",
    component: _b93ab882,
    name: "agenda"
  }, {
    path: "/begeleiders",
    component: _7c26e0f1,
    name: "begeleiders"
  }, {
    path: "/beheerder",
    component: _0921ed24,
    name: "beheerder"
  }, {
    path: "/blogs",
    component: _31436e9e,
    name: "blogs"
  }, {
    path: "/documenten",
    component: _c44d6d92,
    name: "documenten"
  }, {
    path: "/facturen",
    component: _0daecdb0,
    name: "facturen"
  }, {
    path: "/forum",
    component: _ea4c8684,
    name: "forum"
  }, {
    path: "/handleiding",
    component: _7480d6c8,
    name: "handleiding",
    children: [{
      path: ":slug",
      component: _45e843cc,
      name: "handleiding-slug"
    }]
  }, {
    path: "/leerlingen",
    component: _64034cbb,
    name: "leerlingen"
  }, {
    path: "/login",
    component: _abb889f4,
    name: "login"
  }, {
    path: "/mijn-profiel",
    component: _28ed185d,
    name: "mijn-profiel"
  }, {
    path: "/oefeningen",
    component: _ec03b0b0,
    name: "oefeningen"
  }, {
    path: "/opdrachten",
    component: _31b4b5bc,
    name: "opdrachten"
  }, {
    path: "/overige-informatie",
    component: _65d542c8,
    name: "overige-informatie"
  }, {
    path: "/praktijk",
    component: _3999d2d8,
    name: "praktijk"
  }, {
    path: "/sessies",
    component: _ff436276,
    name: "sessies"
  }, {
    path: "/wachtwoord-vergeten",
    component: _310e0076,
    name: "wachtwoord-vergeten"
  }, {
    path: "/activiteiten/aanmaken",
    component: _faae2162,
    name: "activiteiten-aanmaken"
  }, {
    path: "/begeleider/geen-toegang",
    component: _51b8127e,
    name: "begeleider-geen-toegang"
  }, {
    path: "/begeleiders/toevoegen",
    component: _56d6636d,
    name: "begeleiders-toevoegen"
  }, {
    path: "/beheerder/activiteiten",
    component: _dbffa720,
    name: "beheerder-activiteiten"
  }, {
    path: "/beheerder/blogs",
    component: _798abbb4,
    name: "beheerder-blogs"
  }, {
    path: "/beheerder/facturen",
    component: _6876cf93,
    name: "beheerder-facturen"
  }, {
    path: "/beheerder/gebruikers",
    component: _1cbd04be,
    name: "beheerder-gebruikers"
  }, {
    path: "/leerlingen/toevoegen",
    component: _40b84b92,
    name: "leerlingen-toevoegen"
  }, {
    path: "/opdrachten/archief",
    component: _afcdc3c4,
    name: "opdrachten-archief"
  }, {
    path: "/overige-informatie/toevoegen",
    component: _1c4201c4,
    name: "overige-informatie-toevoegen"
  }, {
    path: "/praktijk/abonnementen",
    component: _5f1f07b0,
    name: "praktijk-abonnementen"
  }, {
    path: "/praktijk/facturen",
    component: _43b4145f,
    name: "praktijk-facturen"
  }, {
    path: "/praktijk/opdrachten",
    component: _42a5fcb0,
    name: "praktijk-opdrachten"
  }, {
    path: "/praktijk/begeleiders/leerling-dossier",
    component: _32c3a2fc,
    name: "praktijk-begeleiders-leerling-dossier"
  }, {
    path: "/beheerder/activiteiten/:slug",
    component: _df6ce9b0,
    name: "beheerder-activiteiten-slug"
  }, {
    path: "/beheerder/blogs/:slug",
    component: _7cf7fe44,
    name: "beheerder-blogs-slug"
  }, {
    path: "/beheerder/gebruikers/:slug",
    component: _1b066376,
    name: "beheerder-gebruikers-slug"
  }, {
    path: "/praktijk/facturen/:slug",
    component: _41fd7317,
    name: "praktijk-facturen-slug"
  }, {
    path: "/activiteiten/:slug",
    component: _7922823d,
    name: "activiteiten-slug"
  }, {
    path: "/begeleiders/:slug",
    component: _7a703fa9,
    name: "begeleiders-slug"
  }, {
    path: "/blogs/:slug",
    component: _34b0b12e,
    name: "blogs-slug"
  }, {
    path: "/facturen/:slug",
    component: _111c1040,
    name: "facturen-slug"
  }, {
    path: "/leerlingen/:slug",
    component: _1a198fb6,
    name: "leerlingen-slug"
  }, {
    path: "/oefeningen/:slug",
    component: _ef70f340,
    name: "oefeningen-slug"
  }, {
    path: "/opdrachten/:slug",
    component: _7b831812,
    name: "opdrachten-slug"
  }, {
    path: "/overige-informatie/:slug?",
    component: _641ea180,
    name: "overige-informatie-slug"
  }, {
    path: "/praktijk/:slug",
    component: _37e33190,
    name: "praktijk-slug"
  }, {
    path: "/sessies/:slug",
    component: _7ea7ad7d,
    name: "sessies-slug"
  }, {
    path: "/wachtwoord-instellen/:token?",
    component: _170ee4e7,
    name: "wachtwoord-instellen-token"
  }, {
    path: "/wachtwoord-vergeten/:token?",
    component: _e522ded2,
    name: "wachtwoord-vergeten-token"
  }, {
    path: "/leerlingen/:slug/bewerken",
    component: _024136bf,
    name: "leerlingen-slug-bewerken"
  }, {
    path: "/leerlingen/:slug/informatie",
    component: _13e277c7,
    name: "leerlingen-slug-informatie"
  }, {
    path: "/leerlingen/:slug/sessieverslag-schrijven",
    component: _9193d322,
    name: "leerlingen-slug-sessieverslag-schrijven"
  }, {
    path: "/leerlingen/:slug/aantekeningen/toevoegen",
    component: _2bf256d9,
    name: "leerlingen-slug-aantekeningen-toevoegen"
  }, {
    path: "/leerlingen/:slug/aantekeningen/:id?",
    component: _15273ff6,
    name: "leerlingen-slug-aantekeningen-id"
  }, {
    path: "/leerlingen/:slug/informatie/:aanvulling",
    component: _17ba36e8,
    name: "leerlingen-slug-informatie-aanvulling"
  }, {
    path: "/leerlingen/:slug/sessieverslagen/:verslag_id",
    component: _d5f4ef68,
    name: "leerlingen-slug-sessieverslagen-verslag_id"
  }, {
    path: "/leerlingen/:slug/informatie/:aanvulling/aanpassen",
    component: _13df6602,
    name: "leerlingen-slug-informatie-aanvulling-aanpassen"
  }, {
    path: "/leerlingen/:slug/sessieverslagen/:verslag_id?/aanpassen",
    component: _774a29c2,
    name: "leerlingen-slug-sessieverslagen-verslag_id-aanpassen"
  }, {
    path: "/opdrachten/:slug/:leerling",
    component: _05720340,
    name: "opdrachten-slug-leerling"
  }, {
    path: "/",
    component: _3f07d022,
    name: "index"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
