<template>
  <div>
    <div class="flex flex-col container items-center pt-32">
      <header>
        <nuxt-link to="/" class="w-1/4">
          <img src="~/assets/img/logo.svg" class="object-contain" alt="Mijn Superkracht.nl">
        </nuxt-link>
        <h1 class="w-full h2 text-secondary py-6">Log in om gebruik te maken van het portaal</h1>
      </header>

      <main class="pb-6">
        <p class="text-center">
          Nog geen account als begeleider of praktijk?
          <a :href="nuxtUrl('/registreren')" class="link-secondary">Registreer</a>
          hier!<br>
          Nog geen account als leerling, ouder of overige betrokkene? Meld het bij je begeleider!
        </p>
      </main>

      <form class="w-56 flex flex-col items-center mb-16" @submit.prevent="submit">
        <text-field
          v-if="!otp"
          id="email"
          v-model="form.email"
          type="email"
          required
          class="w-full"
          autocomplete="email"
        >
          E-mailadres
        </text-field>
        <text-field
          v-if="!otp"
          id="password"
          v-model="form.password"
          type="password"
          required
          class="w-full"
          autocomplete="current-password"
        >
          Wachtwoord
        </text-field>

        <text-field v-if="otp" id="2fa_code" v-model="form.code" class="w-full" autocomplete="one-time-code">
          Verificatiecode
        </text-field>
        <button class="btn btn-secondary mt-4" type="submit" :disabled="loading">
          Inloggen
        </button>
      </form>

      <nuxt-link to="/wachtwoord-vergeten" class="link-secondary text-sm">Wachtwoord vergeten?</nuxt-link>
    </div>
  </div>
</template>

<script>
import TextField from '~/components/forms/TextField';

export default {
  layout: 'login',
  middleware: 'guest',
  components: {TextField},
  data: () => ({
    form: {
      email: null,
      password: null,
      code: null,
    },
    loading: false,
    otp: false,
  }),
  methods: {
    async submit() {
      this.loading = true;
      try {
        this.form['2fa_code'] = this.form.code;
        await this.$auth.loginWith('local', {data: this.form});
        await this.$router.push({name: 'index'});
      } catch (e) {
        if (e.response) {
          if (e.response.status === 422) {
            this.$toast.error('Deze combinatie van e-mailadres en wachtwoord is ongeldig.');
          } else if (e.response.status === 423) {
            this.$toast.error(e.response.data?.errors || 'Je hebt te veel ongeldige inlogpogingen gedaan.');
          } else if (e.response.status === 428) {
            this.otp = true;
          } else if (e.response.status === 412) {
            this.$toast.error('De 2FA code is ongeldig. Probeer opnieuw.');
          }
        } else {
          this.$toast.error('Er is een fout opgetreden tijdens het inloggen.');
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
