<template>
  <div class="w-4/5 md:w-1/2 mx-auto my-16">
    <template v-if="error.statusCode === 410 || error.statusCode === 404">
      <div class="prose">
        <h1>{{ page.title }}</h1>
        <!--eslint-disable-next-line-->
        <div v-html="page.tekst"/>
      </div>
      <nuxt-link to="/" class="btn btn-secondary">
        Terug naar de Homepage
      </nuxt-link>
    </template>
    <h1 v-else>
      Er is een fout opgetreden
    </h1>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    error: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: mapState(['page']),
  async created() {
    await this.$store.dispatch('getPage', '404');
  },
  head() {
    return {
      title: this.page.meta ? this.page.meta.title : this.page.title,
      meta: [
        this.page.meta && this.page.meta.description
          ? {
            hid: 'description',
            name: 'description',
            content: this.page.meta.description,
          }
          : {},
      ],
    };
  },
};
</script>
