<template>
  <div class="flex flex-col bg-secondary-light">
    <div
      class="sidebar flex-grow transition-all duration-300 ease"
      :class="{'mobile-active': showMenu}"
      :style="initMenu ? 'display: block;' : ''"
    >
      <ul class="sidebar--menu">
        <li v-for="item in menuItems" :key="item.title">
          <nuxt-link v-if="!isExternalUrl(item.url)" :to="item.url" :class="{'bg-secondary': $route.path === item.url}">
            <div class="inline-block w-1/2 text-left">
              {{ item.title }}
            </div>
          </nuxt-link>
          <a v-else :href="item.url" target="_blank" rel="noopener" :class="{'bg-secondary': $route.path === item.url}">
            <div class="inline-block w-1/2 text-left">
              {{ item.title }}
            </div>
          </a>
        </li>
      </ul>
      <footer class="sidebar--footer">
        <log-out/>
      </footer>
    </div>
    <div class="placeholder-sidebar"/>
  </div>
</template>

<script>
import LogOut from '~/components/base/LogOut';
import {BEGELEIDER, LEERLING, OUDER, BETROKKENE} from '~/plugins/constants';
import roles from '~/plugins/mixins/roles';

export default {
  name: 'Sidebar',
  components: {LogOut},
  mixins: [roles],
  data: () => ({
    initMenu: false,
    showMenu: false,
    completeMenu: [
      {title: 'Dashboard', url: '/', roles: [BEGELEIDER, LEERLING, OUDER, BETROKKENE]},
      {title: 'Leerlingen', url: '/leerlingen', roles: [BEGELEIDER, OUDER, BETROKKENE]},
      {title: 'Begeleiders', url: '/begeleiders', roles: [BEGELEIDER], praktijkbeheerder: true},
      {title: 'Opdrachten', url: '/opdrachten', roles: [BEGELEIDER], service: true},
      {title: 'Abonnementen', url: '/abonnementen', roles: [BEGELEIDER]},
      {title: 'Documenten', url: '/documenten', roles: [BEGELEIDER, OUDER, BETROKKENE, LEERLING]},
      {title: 'Facturen', url: '/facturen', roles: [BEGELEIDER, OUDER, BETROKKENE], permission_id: 4, service: true},
      {title: 'Activiteiten', url: '/activiteiten', roles: [BEGELEIDER, OUDER, BETROKKENE, LEERLING]},
      {title: 'Handleiding', url: '/handleiding', roles: [BEGELEIDER, OUDER, BETROKKENE, LEERLING]},
      {title: 'Blogs', url: '/blogs', roles: [BEGELEIDER]},
      {title: 'Huddle', url: 'https://mijnsuperkracht.thehuddle.nl/', roles: [BEGELEIDER]},
      {title: 'Oefeningen', url: '/oefeningen', roles: [OUDER, BETROKKENE, LEERLING], permission_id: 0},
      {title: 'Overige informatie', url: '/overige-informatie', roles: [OUDER, BETROKKENE, LEERLING], permission_id: 3},
      {title: 'Agenda', url: '/agenda', roles: [OUDER, BETROKKENE, LEERLING, BEGELEIDER], permission_id: 2, service: true},
      {title: 'Sessies', url: '/sessies', roles: [LEERLING, OUDER, BETROKKENE, BEGELEIDER], permission_id: 1, service: true},

    ],
  }),
  computed: {
    menuItems() {
      if (!this.$auth.loggedIn) {
        return [];
      }

      return this.completeMenu.filter((item) => {
        const hasRole = item.roles.includes(this.$auth.user.role_id);

        if (this.$auth.user.role_id === BEGELEIDER) {
          if (item.service && !this.userHasService(item.title)) {
            return false;
          }

          return !(item.praktijkbeheerder ?? false)
            ? hasRole
            : hasRole && (this.isPraktijkbeheerder ?? false);
        }

        let hasPermission;
        if ('permission_id' in item) {
          hasPermission = this.userHasPermission(item.permission_id);
        } else {
          hasPermission = true;
        }

        return hasRole && hasPermission;
      });
    },
  },
  watch: {
    $route() {
      this.hideMenu(false);
    },
  },
  created() {
    this.$nuxt.$on('toggleSidebar', this.handleSidebarOpen);
  },
  beforeDestroy() {
    this.$nuxt.$off('toggleSidebar', this.handleSidebarOpen);
  },
  methods: {
    hideMenu(animation = true) {
      this.showMenu = false;

      if (animation) {
        setTimeout(() => {
          this.initMenu = false;
        }, 300);
      } else {
        this.initMenu = false;
      }
    },
    openMenu() {
      this.initMenu = true;

      setTimeout(() => {
        this.showMenu = true;
      }, 50);
    },
    handleSidebarOpen() {
      if (this.showMenu) {
        this.hideMenu();

        document.body.classList.remove('overflow-hidden');
      } else {
        this.openMenu();

        document.body.classList.add('overflow-hidden');
      }
    },
    isExternalUrl(url) {
      return url.startsWith('http');
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  @apply hidden absolute z-50 bottom-0 pt-4 bg-secondary-light text-white font-bold overflow-auto opacity-0;
  height: calc(100vh - 4rem);
  width: 50px;
  top: 4rem;

  @screen lg {
    @apply static flex flex-col justify-between pt-10 opacity-100;
    width: 260px;
  }

  &--menu {
    @apply flex flex-col;

    li a {
      @apply flex justify-center w-full py-4 whitespace-no-wrap transition duration-300;

      &:hover {
        @apply bg-secondary;
      }
    }
  }

  &--footer {
    @apply bg-secondary-dark py-4 text-center sticky w-full bottom-0;

    @screen lg {
      @apply relative py-10;
    }
  }
}

.mobile-active {
  width: 260px;
  opacity: 1;
}

.placeholder-sidebar {
  width: inherit;
}
</style>
